import { Language } from '@straetus/react/interfaces'
import { addMessagesToCache, getMessagesFromCache } from '@straetus/react/modules/language'

export const getMessages = (language: Language) => {
  const cache = getMessagesFromCache(language)

  if (cache) {
    return cache
  }

  // Suspense is based on ErrorBoundary
  // throwing a promise will cause <SomeLoadingComponent /> to render until the promise resolves
  throw loadMessages(language)
}

export const fetchTranslations = (languageCode: string) => (
  fetch(`https://cdn.simplelocalize.io/9a7827c0c21241ac999b9692c3b5f1f7/_latest/${languageCode}`)
    .then((response) => response.json())
    .catch(() => import('../translations/en.json'))
)

export const loadMessages = async (language: Language) => {
  let dateMessages

  const locale = language.toLowerCase()

  switch (locale) {
    case 'nl':
      dateMessages = await import('date-fns/locale/nl')
      break

    case 'de':
      dateMessages = await import('date-fns/locale/de')
      break

    case 'pl':
      dateMessages = await import('date-fns/locale/pl')
      break

    case 'fr':
      dateMessages = await import('date-fns/locale/fr')
      break

    case 'dk':
    case 'da':
      dateMessages = await import('date-fns/locale/da')
      break

    case 'ro':
      dateMessages = await import('date-fns/locale/ro')
      break

    case 'he':
      dateMessages = await import('date-fns/locale/he')
      break

    default:
      dateMessages = await import('date-fns/locale/en-GB')
  }

  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages.default)

  return messages
}
